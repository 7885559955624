import { SxProps, TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { ChangeEvent } from 'react';

interface FormikTextFieldProps {
    name: string;
    label: string;
    size?: 'small' | 'medium';
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    sx?: SxProps;
    required?: boolean;
    fullWidth?: boolean;
    type?: string;
    className?: string;
}
const FormikTextField = ({
    name,
    label,
    size = 'small',
    disabled,
    onChange,
    sx,
    required,
    fullWidth,
    type,
    className,
}: FormikTextFieldProps) => {
    return (
        <Field name={name}>
            {({ form: { setFieldValue, handleBlur }, meta: { value, touched, error } }: FieldProps) => {
                return (
                    <TextField
                        size={size}
                        required={required}
                        fullWidth={fullWidth}
                        label={label}
                        value={value}
                        disabled={disabled}
                        error={touched && Boolean(error)}
                        helperText={touched && error}
                        onChange={(e) => {
                            setFieldValue(name, e.target.value);
                            onChange?.(e);
                        }}
                        onBlur={handleBlur(name)}
                        sx={sx}
                        type={type}
                        className={className}
                    />
                );
            }}
        </Field>
    );
};

export default FormikTextField;
