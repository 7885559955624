import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Checkbox, FormControlLabel, Link as MuiLink, Grid, SxProps, Box } from '@mui/material';
import * as Yup from 'yup';
import { PrimaryButton } from '@/components/common';
import { routes } from '@/constants/routes';
import { useTranslation } from '@/hooks/translations';
import AuthService from '@/services/auth';
import { LoginResponse } from '@/interfaces/auth/reponse';
import { onPushEvent } from '@/services/lib/gtm';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import FormikTextField from '@/components/FormikTextField';
import { recaptchaSiteKey } from '@/constants/configs';
import ReCAPTCHA from 'react-google-recaptcha';
import GoogleButton from 'react-google-button';

import { disablePasswordLogin, ILoginProps } from '.';

interface ILoginComponentProps extends ILoginProps {
    setLoginAsAdmin: (value: boolean) => void;
    setLoginResponse: (response: LoginResponse) => void;
    setPage: (page: 'login' | 'mfa-code' | 'mfa-enable') => void;
}

const PrimaryButtonProps: SxProps = {
    marginTop: '1rem',
    fontWeight: 700,
    fontSize: '1rem',
    borderRadius: '0.5rem',
};

const LoginComponent = ({
    loginAsAdmin,
    setLoginAsAdmin,
    setLoginResponse,
    accessSelectRestaurant,
    setError,
    loading,
    setLoading,
    setPage,
}: ILoginComponentProps) => {
    const { t } = useTranslation('common');
    const authService = AuthService.getInstance();
    const router = useRouter();
    const { lang, code } = router.query as { lang?: string; code?: string };
    const oauthAdminLogin = disablePasswordLogin && router.pathname === '/google';

    const { enqueueSnackbar } = useSnackbar();

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t('Invalid email ID')).required(t('Email is required')),
            password: Yup.string().required(t('Password is required')),
        }),
        onSubmit: (values) => {
            setError('');
            setLoading(true);
            recaptchaRef.current
                ?.executeAsync()
                .then((value) => {
                    return authService
                        .loginV2({ ...values, type: loginAsAdmin ? 'admin' : 'vendor', recaptcha: value || '' })
                        .then((res) => {
                            onPushEvent('user_successfully_logged_in');
                            setLoginResponse(res);
                            if (res.data.challengeName) {
                                setPage?.('mfa-code');
                                // } else if (localStorage.getItem(isFirstLoginKey) !== form.email) {
                            } else {
                                accessSelectRestaurant(res);
                            }
                        })
                        .catch(() => {
                            onPushEvent('user_login_failed');
                            enqueueSnackbar(t('Incorrect credentials'), { variant: 'error' });
                        });
                })
                .finally(() => setLoading(false));
        },
    });

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginAsAdmin(event.target.checked);
    };

    const oauthHandler = () => {
        setLoading(true);
        recaptchaRef.current?.executeAsync().then((token) => {
            authService
                .googleAuth(token || '')
                .then((res) => {
                    window.location.href = res.data;
                })
                .catch((err: any) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const oauthLoginHandler = (oauthToken: string) => {
        if (loading) {
            return;
        }

        router.replace('/google');

        setLoading(true);
        setLoginAsAdmin(true);
        authService
            .googleAuthLogin({
                code: oauthToken,
            })
            ?.then((res: any) => {
                router.replace('/');
                onPushEvent('user_successfully_logged_in');
                setLoginResponse(res);
                if (res.data.challengeName) {
                    setPage?.('mfa-code');
                } else {
                    accessSelectRestaurant(res, true);
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useMemo(() => {
        if (!code) {
            return;
        }

        oauthLoginHandler(code);
    }, [code]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    {oauthAdminLogin && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                minWidth: '300px',
                                ' > div': {
                                    width: '100% !important',
                                },
                            }}
                        >
                            <GoogleButton disabled={loading} onClick={oauthHandler} />
                        </Grid>
                    )}
                    {!oauthAdminLogin && (
                        <>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name={'email'}
                                    label={t('Email')}
                                    type="email"
                                    size="medium"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name={'password'}
                                    label={t('Password')}
                                    type="password"
                                    size="medium"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {!disablePasswordLogin && (
                                        <FormControlLabel
                                            control={<Checkbox checked={loginAsAdmin} onChange={handleRoleChange} />}
                                            label={t('Login as admin')}
                                        />
                                    )}
                                    <Box sx={{ flex: '1 1' }} />
                                    <Link href={{ pathname: routes.RESET_PASSWORD, query: { lang } }} passHref>
                                        <MuiLink underline="hover">{t('Forgot Password?')}</MuiLink>
                                    </Link>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
                {!oauthAdminLogin && (
                    <PrimaryButton
                        id="login-btn"
                        disabled={loading}
                        type="submit"
                        text={loading ? t('Logging in') : t('Login')}
                        fullWidth
                        styles={PrimaryButtonProps}
                    />
                )}
            </form>
            {recaptchaSiteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />}
        </FormikProvider>
    );
};

export default LoginComponent;
