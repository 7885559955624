import { useState } from 'react';
import Link from 'next/link';
import { PrimaryButton } from '@/components/common';
import { routes } from '@/constants/routes';
import { useTranslation } from '@/hooks/translations';
import { Box, Card, CardContent, TextField, Link as MuiLink, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import { Field, FormikHelpers, FieldProps, useFormik, FormikProvider } from 'formik';
import { capitalSmallSpecialCharacter } from '@/constants/regexes';
import AuthService from '@/services/auth';
import styles from './index.module.scss';

interface ResetPasswordFormData {
    email: string;
    password: string;
    password_confirmation: string;
    code: string;
}

export default function verify() {
    const authService = AuthService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const [error, setError] = useState<string>('');
    const { email, token, lang } = router.query as { email?: string; token?: string; lang?: string };

    const initialValues: ResetPasswordFormData = {
        email: email || '',
        password: '',
        password_confirmation: '',
        code: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(t('Please enter a valid email')).required(t('The email field is required!')),
        password: Yup.string()
            .matches(capitalSmallSpecialCharacter, t('1 capital case 1 small case 1 special character must be there'))
            .min(8, t('The password must be of min 8 characters'))
            .required(t('The password field is required!')),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t("The confirm password doesn't match"))
            .required(t('The password field is required!')),
        code: Yup.string().required(t('The verification code field is required!')),
        token: Yup.string().nullable(),
    });

    const formik = useFormik<ResetPasswordFormData>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values: ResetPasswordFormData, { setSubmitting }: FormikHelpers<ResetPasswordFormData>) => {
            setSubmitting(true);
            authService
                .resetPasswordVerify({
                    username: values.email,
                    new_password: values.password,
                    verification_code: values.code,
                    token: token || '',
                })
                .then((res) => res.data)
                .then(() => {
                    router.push({ pathname: routes.LOGIN, query: { lang } });
                })
                .catch(() => setError(t('Something went wrong')))
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    return (
        <Box className={styles.container}>
            <Card className={styles.verifyCard}>
                <CardContent>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                            <Typography color="primary" className={styles.headerText} variant="h6">
                                {t('QlubPay Reset Password')}
                            </Typography>
                            <Field name="email">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        fullWidth
                                        required
                                        label={t('Email')}
                                        {...field}
                                        error={!!meta.error}
                                        helperText={meta.error}
                                        id="email-field"
                                        margin="normal"
                                    />
                                )}
                            </Field>
                            <Field name="password">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        fullWidth
                                        label={t('Password')}
                                        type="password"
                                        id="password"
                                        margin="normal"
                                        {...field}
                                        error={!!meta.error}
                                        helperText={meta.error}
                                    />
                                )}
                            </Field>
                            <Field name="password_confirmation">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        fullWidth
                                        label={t('Confirm Password')}
                                        type="password"
                                        id="cpassword"
                                        margin="normal"
                                        {...field}
                                        error={!!meta.error}
                                        helperText={meta.error}
                                    />
                                )}
                            </Field>
                            <Field name="code">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        fullWidth
                                        label={t('Verification Code')}
                                        required
                                        type="password"
                                        {...field}
                                        error={!!meta.error}
                                        helperText={meta.error}
                                        id="code-field"
                                        margin="normal"
                                    />
                                )}
                            </Field>
                            {error && (
                                <Typography textAlign="center" color="error">
                                    {error}
                                </Typography>
                            )}
                            <PrimaryButton
                                id="login-btn"
                                type="submit"
                                text={t('Send Email')}
                                fullWidth
                                styles={{
                                    marginTop: '1rem',
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    borderRadius: '0.5rem',
                                }}
                            />
                            <Grid item className={styles.backtoLoginLink}>
                                <Link href={{ pathname: routes.LOGIN, query: { lang } }} passHref>
                                    <MuiLink className="reset-password-link" underline="hover">
                                        {t('Back to Login')}
                                    </MuiLink>
                                </Link>
                            </Grid>
                        </form>
                    </FormikProvider>
                </CardContent>
            </Card>
        </Box>
    );
}
